$ens-text-color: #856404;
.m-ens-alert {
  @extend .alert;
  @extend .alert-warning;
  @extend .m-2;

  font-size: 5rem;

  &__heading {
    @extend .alert-heading;
    font-size: 1.75rem;
    color: $ens-text-color;
  }

  &__lead {
    @extend .lead;
    color: $ens-text-color;
  }

  &__date-time {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

}