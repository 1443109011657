@media only screen and (max-width: 767px) {
	.m-sidebar-landing-page__heading,.m-sidebar-landing-page__content {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
	.sec-w-sidebar aside.m-sidebar-landing-page {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.m-sidebar-landing-page__content,
	.m-sidebar-landing-page__content p,
	{
		font-size: 16px;
		line-height: 16px;
	}
}

@media only screen and (min-width: 768px) {
	.m-sidebar-landing-page__content,
	.m-sidebar-landing-page__content p,
	{
		font-size: 14px;
		line-height: 14px;
	}
}

.m-sidebar-landing-page__heading {
	@extend .h2;
	@extend .d-md-none;
	font-size: 25px;
	color: $primary;
	text-transform: uppercase;
}
