
$breadcrumb-padding: 0.3rem;
$breadcrumb-font-size: 14px; //break rem unit standard for pixel-perfect adjustments

.m-breadcrumb {
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 20px 0;
    background: #fff;


    &__item {
        color: $black;
        padding-right: $breadcrumb-padding;
        display: inline-block;
        font-size: $breadcrumb-font-size;

        &--current {
            color: $gray-800;
            cursor: not-allowed;
        }
        
        a {
            color: $black;
            text-decoration: none;
            box-sizing: border-box;
        }

        a:hover{
            color: $black;
            border-bottom: 1px solid #c2c2c2;
        }
    }
    // add slash between breadcrumb items
    &__item:not(:first-child)::before{
        padding-right: $breadcrumb-padding;
        color: #d3d3d3;
        content: "/";
    }
    
}