// styles limited to impacting items produced in the WYSIWYG

// all rules in this folder should be surrounded with .content-detail-sec {}
.content-detail-sec {
    @import 'image-and-figure';
    @import 'lists';
    @import 'table';
    @import 'links';
    @import 'blockquote';
    @import 'hr';
}
