/*
    WCAG 2.4.7 Focus Visible - Level AA
    Implements Technique G195 - Focus is >2px, ensuring proper visibility even if contrast ratio becomes less than 3:1 
    Source: https://www.w3.org/WAI/WCAG21/Techniques/general/G195.html
*/
@mixin focus-defaults() {
  a:focus,
  button:focus,
  *:focus {
    color: inherit;
    outline: 3px solid $primary-dark;
    outline-offset: 1px;
    outline-color: $primary-dark;
  }
}

@mixin focus-dark() {
  a:focus,
  button:focus,
  *:focus {
    outline-color: $white;
    color: $white;
  }
}
