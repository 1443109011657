// Slate CRM forms are used by Admissions
// We cannot control only the wrapping class of .slate-crm-form. The rest of the markup is generated by Slate.

.slate-crm-form {

    font-size: 1rem;

    .form_h2 {
        @extend .h3;
        font-size: 1.2rem;
    }

    .form_question {
        @extend .form-group;

        box-sizing: border-box !important;
        margin:-.5em -.5em .5em -.5em !important;
        padding: .5em;
    }
    
    .form_question[data-required="1"] > fieldset > .form_label > legend::before {
        color: rgb(82, 0, 0);
        content: "* ";
    }

    .form_question[data-required="1"] > label.form_label::before {
        color: rgb(82, 0, 0);
        content: "* ";
    }

    div.form_question .form_label, div.form_question label {
        padding-top: 1rem;
    }
    
    input:not([type="checkbox"]):not([type="radio"]) {
        @extend .form-control;
    }
    
    input[type="checkbox"],
    input[type="radio"] {
        margin-right: .5rem;
    }

    textarea {
        @extend .form-control;
    }

    select {
        @extend .form-control;
        display: inline-block;
        width: inherit;
    }

    legend {
        font-size: 1rem;
        font-weight: 800;
    }

    fieldset {
        border-left: 2px solid $umt-light-gray;
        padding-left: 1rem;
        margin: 1rem 0;
    }

    .form_responses {
        .form_responses {
            @extend .form-group;
        }
    } 
    
    .form_birthdate {
        .form_responses {
            @extend .form-inline;
        }
    }
    
    button {
        @extend .btn;
        @extend .btn-primary;
    }

    .form_success {
        @extend .alert;
        @extend .alert-success;
    }
    
    .replicate_insert {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-sm;
    }

    .replicate_destination::before {
      content: '';
      display: block;
      height: 2rem;
    }

}


// Style from vendor that the above replaces:

//     div.form_question {
//         box-sizing:border-box;
//         margin:-.5em -.5em .5em -.5em;
//         padding:.5em;
//        }
//        div.form_question .form_label,
//        div.form_question label {
//         display:block;
//        }
//        div.form_question fieldset {
//         border:0;
//         margin:0;
//         padding:0;
//         min-width:0;
//        }
//        div+div.form_h1 {
//         margin-top:15px;
//        }
//        div.form_h1 .form_label {
//         font-size:1.4em;
//        }
//        div.form_h2 .form_label {
//         font-size:1.3em;
//        }
//        div.form_h3 .form_label {
//         font-size:1.1em;
//        }
//        div.form_p .form_label {
//         font-weight:normal;
//        }
//        div.form_header .form_label {
//         font-weight:bold;
//         background:#ccc;
//         padding:4px 5px;
//        }
//        div.form_responses {
//         white-space:pre-wrap;
//        }
//        div.form_response input+label {
//         display:inline;
//         font-weight:normal;
//         margin-left:.5em;
//        }
//        div.form_response span.full_message {
//         font-weight:bold;
//         margin-left:1em;
//         color:#c00;
//         vertical-align:middle;
//        }
//        div.form_question.selected,
//        div.form_question.required {
//         background-color:#ffc;
//        }
//        div.form_question.required {
//         background-color:#fcc;
//        }
//        .form_success {
//         border:2px solid #0a0;
//         color:#0a0;
//         font-size:1.25em;
//         padding:.5em;
//        }
//        .form_question {
//         position:relative;
//        }
//        div.form_page+div.form_page {
//         margin-top:10px;
//        }
//        div.form_page input,
//        div.form_page label {
//         vertical-align:middle;
//        }
//        div.form_page input[type=password],
//        div.form_page input[type=text],
//        div.form_page input[type=email],
//        div.form_page input[type=tel],
//        div.form_page input:not([type]),
//        div.form_page select,
//        div.form_page textarea {
//         box-sizing:border-box;
//         max-width:100%;
//        }
//        div.form_question+div.form_question[data-type="plugin:widget"] {
//         margin-top:10px;
//        }
//        div.form_question[data-type="plugin:widget"]+div.form_question {
//         margin-bottom:10px;
//        }
//        div.form_question[data-type="likert"]>fieldset>.form_responses_labels>.form_response_label,
//        div.form_question[data-type="likert"]>fieldset>.form_responses>.form_response {
//         box-sizing:border-box;
//         display:inline-block;
//         padding:0 4px;
//         text-align:center;
//        }
//        div.form_question[data-type="address"]>fieldset>.form_responses,
//        div.form_question[data-type="location"]>fieldset>.form_responses,
//        div.form_question[data-type="location_nopostal"]>fieldset>.form_responses {
//         padding:0;
//        }
//        div.form_question.form_layout_stacked[data-type="address"]>fieldset>.form_responses>div,
//        div.form_question.form_layout_stacked[data-type="location"]>fieldset>.form_responses>div,
//        div.form_question.form_layout_stacked[data-type="location_nopostal"]>fieldset>.form_responses>div {
//         margin-top:5px;
//        }
//        div.form_question.form_layout_table input,
//        div.form_question.form_layout_table textarea {
//         box-sizing:border-box;
//         max-width:100%;
//        }
//        @media(min-width:481px) {
//         div.form_question.form_layout_table {
//          margin:0;
//          padding:0;
//          width:100%;
//          display:table;
//         }
//         div.form_question.form_header.form_layout_table {
//          margin:0;
//          padding:0;
//         }
//         div.form_question.form_layout_table .form_label {
//          box-sizing:border-box;
//          display:table-cell;
//          padding:4px 5px;
//          width:150px;
//          vertical-align:top;
//         }
//         div.form_question.form_layout_table .form_responses,
//         div.form_question.form_layout_table .form_responses_labels {
//          box-sizing:border-box;
//          display:table-cell;
//          padding:4px 5px;
//          vertical-align:top;
//         }
//         div.form_question.form_layout_table[data-type="likert"] {
//          display:block;
//         }
//         div.form_question.form_layout_table[data-type="likert"] .form_label {
//          display:inline-block;
//         }
//         div.form_question.form_layout_table[data-type="likert"] .form_responses,
//         div.form_question.form_layout_table .form_responses_labels {
//          display:inline-block;
//          width:calc(100% - 150px);
//         }
//         div.form_h1.form_layout_table,
//         div.form_h1.form_layout_table .form_label,
//         div.form_h1.form_layout_table .form_responses,
//         div.form_h2.form_layout_table,
//         div.form_h2.form_layout_table .form_label,
//         div.form_h2.form_layout_table .form_responses,
//         div.form_p.form_layout_table,
//         div.form_p.form_layout_table .form_label,
//         div.form_p.form_layout_table .form_responses {
//          display:block;
//          width:inherit !important;
//         }
//         div.form_header.form_layout_table,
//         div.form_header.form_layout_table .form_label,
//         div.form_header.form_layout_table .form_responses {
//          display:block;
//          padding:4px 5px;
//          width:inherit !important;
//         }
//         div.form_question[data-type="likert"]>fieldset>.form_responses {
//          margin-top:0;
//         }
//         div.form_question[data-type="likert"]>fieldset>.form_responses_labels {
//          margin-left:150px;
//         }
//        }
//        @media only screen and (max-width:736px) {
//         div.form_question {
//          margin:0;
//          padding:0 0 .5em 0 !important;
//          width:100% !important;
//         }
//         div.form_question[data-type="likert"]>fieldset>.form_responses_labels {
//          margin-bottom:.5em;
//         }
//         div.form_question[data-type="likert"]>fieldset>.form_responses {
//          margin-top:.5em;
//         }
//        }