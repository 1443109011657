table {
    @extend .table;

    caption {
        padding-top: .75rem;
        padding-bottom: .75rem;
        color: #343a40;
        text-align: left;
        caption-side: top;
        font-size: 1rem;
        font-style: italic;
    }

    tr td,
    thead th {
        padding: 23px 20px;
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
    }
    tr td {
        background: #e1e1e1;
    }
    tr td a{
        text-decoration: underline;
    }
    tr td a:hover {
        color: #70002e;
        text-decoration: none;
    }
}







.table-responsive {
    @extend .table;

    caption {
        padding-top: .75rem;
        padding-bottom: .75rem;
        color: #343a40;
        text-align: left;
        caption-side: top;
        font-size: 1rem;
        font-style: italic;
    }
}