$module-heading-size: 2.4rem;
$normal-font-size: 1.38rem; //This is the rem value to achieve the PX set by the design.

@mixin module-heading($font-size) {
    font-family: "Flama Condensed", Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: $font-size;
}

@mixin sans-serif() {
    font-family: Arial, Helvetica, sans-serif;
}

@mixin module-heading-shadow() {
    text-shadow: 1px 1px 2px #282828;
}