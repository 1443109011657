.page-home {
  background: $primary;
}
.page-home .h1,
.page-home .h2,
.page-home .h3,
.page-home h1,
.page-home h2,
.page-home h3,
.page-home p {
  color: #fff;
}

.navbar {
  background: $primary;
  padding: 0;
}

//navbar and footer link around the logo
.a-um-logo {
  color: #fff;
}

//fix for imported bootstrap style 
//seems to be required due to webpack embedding bootstrap styling that may have not been included before on navbar
//@author: Dan Bowling
.dropdown-menu>.container{
  display: block;
}


.navbar-brand {
  padding: 0;
  margin-right: 50px;
  margin-bottom: 24px;
}
.navbar-brand img {
  width: 190px;
}
.footer-logo img {
  width: 218px;
}
.navbar-left {
  padding: 0;
}

.nav-item {
  padding: 0;
  font-weight: 900;
}

.nav-link {
  padding: 0;
  color: #fff;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 17px 30px;
  position: relative;
  text-decoration: none;
  font-weight: 900;
}
.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #fff;
}
.nav-item > a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #fff;
  content: "";
  -webkit-transition: width 0.35s ease;
  transition: width 0.35s ease;
}
.nav-item:hover > a:after,
.navbar-expand-lg .navbar-nav .nav-item.active a:after {
  width: 100%;
}
.navbar .navbar-collapse {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.navbar-search {
  padding: 37.5px 35px;
  font-size: 27px;
  color: #fff;
  font-weight: normal;
  border-right: 1px solid $primary-light-accent;
}
.navbar-search .search-icon {
  background-image: url(../../images/search-icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  display: block;
}
#um_search_box {
  padding-left: 5px;
}
#um_search_box:focus {
  outline: 1px solid #000;
  background-color: #fff;
}

.navbar-search[aria-expanded="true"] .search-icon {
  background-image: url(../../images/search-icon-close.svg);
}
.info-menu {
  border-left: 1px solid $primary-light-accent;
  border-right: 1px solid $primary-light-accent;
  min-width: 110px;
}
.info-menu li {
  list-style: none;
}

.info-menu li a {
  color: #fff;
  padding: 19px 30px;
  display: block;
  border-bottom: 1px solid $primary-light-accent;
  font-weight: 900;
  text-align: center;
  text-decoration: none;
}
.info-menu li:last-of-type a {
  border-bottom: none;
}
.info-menu li a:hover,
.navbar-search:hover,
.navbar-search[aria-expanded="true"] {
  background: $primary-dark;
  color: #fff;
}

.dropdown-menu {
  border: none;
  border-top: 1px solid $primary-light-accent;
  margin-top: 0;
  width: 100%;
  border-radius: 0;
  background: $primary;
  padding: 28px 0 34px;
  font-size: 13px;
}

.dropdown-menu.search {
  background: #e1e1e1;
  padding-bottom: 55px;
}
.dropdown-menu.search .container {
  max-width: 1110px;
}

.navbar-search[aria-expanded="true"] .fa-search::before {
  content: "\f00d";
}
.search .form-control {
  width: calc(100% - 160px);
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  background: none;
  font-size: 24px;
  color: #000;
  height: 40px;
  padding-left: 0;
}
.search .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}
.search .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}
.search .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
}
.search .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}
.search .btn {
  border-radius: 0;
  font-size: 14px;
  font-weight: 900;
  min-width: 150px;
  letter-spacing: 1px;
}
.search .form-control:focus,
.search .form-control:active,
.search .btn:focus,
.search .btn:active {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* .filter-search .search .form-control {
  width: 675px;
} */
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.dropdown-menu .container {
  padding: 0 90px;
  max-width: 1070px;
}
.dropdown-menu ul li {
  padding-bottom: 12px;
  list-style: none;
  line-height: 17px;
}
.dropdown-menu ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 900;
}
.dropdown-menu ul li a:hover {
  text-decoration: underline;
}
.dropdown-title {
  padding: 10px 0;
  margin-bottom: 0;
  font-size: 25px;
  background: $primary-dark;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  line-height: 40px;
  margin-bottom: 15px;
  font-family: "Flama Condensed";
  text-decoration: none;

  font-weight: 500;
}
.dropdown-title:hover {
  color: #fff;
  text-decoration: underline;
}
.dropdown-col {
  border-right: 1px solid $primary-light-accent;
  padding-left: 20px;

  padding-right: 20px;
}
.dropdown-col:last-of-type {
  border-right: none;
}

.nav-item:hover > .dropdown-menu {
  display: block;
}

.nav-item > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

/* HERO */
.hero-inner {
  padding-top: 260px;
  padding-bottom: 35px;
}
.hero-inner h1,
.hero-inner strong {
  font-size: 60px;
  color: #fff;
  line-height: 60px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.hero-inner strong {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-family: "Flama Condensed";
  font-weight: 500;
}
.hero-inner h1 span {
  display: block;
  line-height: normal;
}
.hero-inner.lg-spc {
  padding-top: 315px;
}
/* FEATURED SECTION */

.featured-section figure {
  margin-bottom: 0;
}
.featured-section .row {
  position: relative;
}
.featured-section .bg-primary * {
  color: #fff !important;
}

.featured-section h3 {
  color: $primary;
  margin-bottom: 23px;
}
.featured-section p {
  font-size: 16px;
  color: #000;
  padding-bottom: 0;
}
.featured-section p + a {
  margin-top: 26px;
}
.featured-section a {
  color: $primary;
  letter-spacing: 1px;
  font-weight: 900;
  display: inline-block;
}
.featured-section .featured-text-sec p + a:hover,
.popup-desc p + a:hover {
  color: #fff !important;
}
.featured-section .bg-white .featured-text-sec p + a:hover {
  color: $primary !important;
}
.featured-text-sec h3 {
  text-transform: none;
}

/* FEATURED BG IMG */
.featured-bgImg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $primary;
}
.featured-bgImg .container {
  position: relative;
  z-index: 1;
}
.featured-bgImg h2,
.featured-bgImg .h2 {
  padding-bottom: 20px;
  color: #fff;
}
.featured-bgImg a {
  font-size: 13px;
  color: #fff;
  font-weight: 900;
  letter-spacing: 2px;
}
.featured-bgImg.w-overlay::after {
  z-index: -1;
}
.w-overlay {
  position: relative;
  z-index: 1;
}
.w-overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* for accessibility contrast */
.w-overlay figcaption {
  text-shadow: 1px 1px 2px black;
}

.centered-text-sec.text-white,
.centered-text-sec.text-white a {
  color: #fff;
}
.centered-text-sec.text-primary h2,
.centered-text-sec.text-primary a:not(.btn) {
  color: $primary;
}
.centered-text-sec.text-primary p {
  color: #000 !important;
  padding-bottom: 35px;
}

.centered-text-sec {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.centered-text-sec a {
  letter-spacing: 2px;
  font-weight: 900;
}
.centered-text-sec .button-group .btn,
.button-group.padd-sml .btn {
  margin: 0 10px 10px;
}
.inner-col h2 span {
  font-size: 120px;
  line-height: 92px;
}

.inner-col [class^="col-"] a {
  color: #fff;
  text-decoration: underline;
}

.two-col-sec .col-sm-8 {
  max-width: 770px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 770px;
  flex: 0 0 770px;
}
.inner-col [class^="col-"] {
  max-width: 41%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41%;
  flex: 0 0 41%;
}
.inner-col [class^="col-"] p {
  padding-bottom: 35px;
  font-family: "serif";
  font-weight: normal;
}
.inner-col [class^="col-"]:last-of-type p {
  padding-bottom: 0;
}
.heading-sec {
  padding-bottom: 15px;
  border-bottom: 1px solid $primary-light-accent;
  margin-bottom: 30px;
}

.upcoming-event.text-primary .heading-sec {
  border-bottom-color: #c2c2c2;
}
.heading-sec.wo-bdr,
.heading-sec.w-bdr-baseline {
  border-bottom: none;
  padding-bottom: 0;
}
.heading-sec.w-bdr-baseline h3 {
  position: relative;
  line-height: 30px;
}
.heading-sec.w-bdr-baseline h3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  background: #c2c2c2;
  width: 100%;
  height: 1px;
  z-index: -1;
}
.heading-sec.w-bdr-baseline span {
  display: inline-block;
  padding: 0 10px 0 0;
  font-size: 40px;
  font-family: "Flama Condensed";
  font-weight: 500;
  text-transform: uppercase;
  background: #fff;
  line-height: 1;
}
.heading-sec h3 span {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
}
.upcoming-event.text-primary span {
  color: #000;
}
.event-list-item {
  color: #fff;
  padding: 30px 0;
  border-bottom: 1px solid $primary-light-accent;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 30px);
  flex: 0 0 calc(50% - 30px);
  max-width: calc(50% - 30px);
  margin: 0 15px;
}
.event-list-item .title {
  margin-bottom: 15px;
}
.upcoming-event.text-primary .event-list-item {
  border-bottom-color: #c2c2c2;
}
.event-list-item:first-of-type,
.event-list-item:nth-of-type(2) {
  padding-top: 0;
}

.event-list-item a {
  color: #fff;
  text-decoration: none;
  display: block;
}
.news-item a:hover,
.news-item a:active,
.news-item a:focus,
.event-list-item a:hover,
.event-list-item a:active,
.event-list-item a:active,
.copyright a:hover,
.copyright a:active,
.copyright a:focus  {
  text-decoration: underline;
  color: #fff;
}

.text-primary .event-list-item a:hover {
  text-decoration: underline;
  color: $primary;
}

.news-item {
  margin-bottom: 30px;
}
.news-item h4 {
  margin-bottom: 0;
}
.news-item a {
  color: #fff;
  margin-bottom: 10px;
}
.news-item-list {
  list-style: none;
}
.news-item-list a {
  text-decoration: none;
}
.news-sec.text-primary *,
.upcoming-event.text-primary * {
  color: $primary;
}

.site-footer {
  padding: 40px 0;
  background: $primary;
}

.site-footer .col {
  border-left: 1px solid $primary-light-accent;
}

.site-footer .col ul {
  margin-bottom: 0;
}
.site-footer .col ul li {
  list-style: none;
  padding-bottom: 12px;
  line-height: 17px;
}
.site-footer .col:first-of-type {
  border-left: none;
}
.site-footer .col ul li:last-of-type {
  padding-bottom: 0;
}
.site-footer .col ul li,
.site-footer .col ul li a {
  color: #fff;
  text-decoration: none;
}
.site-footer .col ul li a:hover {
  text-decoration: underline;
}

.site-footer .col:last-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.site-footer .col:last-of-type ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.site-footer .col:last-of-type li {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 7px;
  font-size: 41px;
}

.copyright {
  text-align: center;
  font-size: 11px;
  padding-top: 50px;
  color: #fff;
}
.copyright a {
  color: #fff;
  text-decoration: underline;
}

.site-footer.secondary {
  background: transparent;
  padding: 0;
}
.site-footer.secondary .row {
  padding: 40px 0;
  background: #636363;
}
.site-footer.secondary .col {
  border-left-color: #acacac;
}

.site-footer.secondary .col:last-of-type {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.breadcrumb-sec {
  border-radius: 0;
  padding: 20px 0;
  background: #fff;
  border-bottom: 1px solid #c2c2c2;
}
.breadcrumb-sec .breadcrumb {
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;
  background: transparent;
}

.breadcrumb-item a {
  color: #000;
  font-size: 14px;
  -webkit-transition: none;
  transition: none;
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #d3d3d3;
}

.breadcrumb-item.active {
  color: #000;
}

.breadcrumb-item a:hover {
  border-bottom: 2px solid #b0b0b0;
  text-decoration: none;
  color: inherit;
}

.secondary-menu {
  background: #636363;
  padding: 30px 0;
}

.secondary-menu ul {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.secondary-menu ul li {
  list-style: none;
  padding-bottom: 13px;
  padding-left: 15px;
  border-left: 1px solid #fff;
  width: 25%;
  display: inline-block;
  max-width: 25%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.secondary-menu ul li a {
  color: #fff;
  text-decoration: none;
}
.secondary-menu ul li:nth-last-child(-n + 4) {
  padding-bottom: 0;
}
.secondary-menu ul li a:hover {
  text-decoration: underline;
}

.secondary-menu .col {
  border-left: 1px solid #acacac;
}
.secondary-menu .col ul li:last-of-type {
  padding-bottom: 0;
}
.stat-info .container .row {
  background: $primary;
  padding: 65px 45px 80px;
}
.stat-info h2 {
  font-size: 120px;
  line-height: 120px;
  margin-bottom: 5px;
}
.stat-info [class^="col-"] {
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  -ms-flex-line-pack: center;

  align-content: center;
}

.stat-info p {
  font-family: "serif";
  font-weight: normal;
}
.program-list {
  margin-bottom: 0;
}
.program-list figcaption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  font-size: 13px;
  z-index: 1;
  width: 100%;
  text-align: center;
  line-height: 17px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.program-list figcaption a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none;
  letter-spacing: 1.5px;
}
.program-list figcaption a:hover {
  text-decoration: underline;
}

.centered-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 0 20px;
}
.centered-caption h3 {
  margin-bottom: 25px;
}
.centered-caption p {
  font-size: 17px;
}
.centered-caption a {
  color: #fff;
  font-weight: 900;
}

.featured-section .program-list figcaption {
  opacity: 0;
}
.featured-section .program-list:hover figcaption {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.featured-section .program-list::after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.featured-section .program-list:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.w-overlay.program-list figcaption {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.accordion-item:first-of-type .accordion-title span {
  padding-top: 0;
  border-top: none;
}
.accordion .accordion-title {
  color: $primary;
  text-transform: uppercase;
}

.accordion-title span {
  padding: 26px 0;
  border-bottom: 1px solid #c2c2c2;
  display: block;
  cursor: pointer;
  font-size: 19px;
}
.accordion-title span i {
  margin-right: 10px;
  font-size: 15px;
}
.accordion-body {
  padding-bottom: 30px;
  border-bottom: 1px solid #c2c2c2;
  padding-top: 30px;
}
.accordion-item .accordion-title span[aria-expanded="true"] .fa-plus:before {
  content: "\f068";
}
.accordion-body h4 {
  letter-spacing: 1px;
  font-weight: 900;
  margin-bottom: 12px;
}
.accordion-body p {
  padding-bottom: 25px;
}
.accordion-body ul {
  padding-left: 40px;
  font-size: 19px;
  margin-bottom: 25px;
}
.accordion-body ul li {
  padding-bottom: 15px;
}
.accordion-body ul li a,
.accordion-body p a {
  text-decoration: underline;
  color: #000;
}
.accordion-body ul li a:hover,
.accordion-body p a:hover {
  text-decoration: none;
}
.accordion-body p a:hover {
  color: $primary;
}

.popup-detail {
  display: block;
  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  background: $primary;

  opacity: 0;

  height: 0;

  -webkit-transform: scale(0);

  transform: scale(0);

  z-index: -1;

  -webkit-transition: all 0.35s ease-in;

  transition: all 0.35s ease-in;

  -webkit-transform-origin: top left;

  transform-origin: top left;
  overflow: hidden;
}
.popup-detail.current {
  display: block;
  opacity: 1;
  height: 100%;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  z-index: 1;
}

.popup-desc {
  padding-top: 72px;
  padding-bottom: 40px;
}

.popup-detail .close {
  padding-top: 20px;
  cursor: pointer;
  opacity: 1;
}
.popup-detail .close:hover {
  opacity: 0.5;
}

.popup-desc span {
  line-height: 22px;
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 20px;
  display: inline-block;
}
.popup-desc p {
  padding-bottom: 25px;
  line-height: 22px;
}

.filter-category h4 {
  font-size: 15px;
  color: $primary;
  padding-bottom: 10px;
  border-bottom: 1px solid #898a8e;
  font-weight: 900;
  margin-bottom: 20px;
  padding-top: 35px;
}
.filter-category h4 a {
  color: $primary;
}
.filter-category h4:first-of-type {
  padding-top: 0;
}

// we choose to hide the program options at this time, but leave them in the markup for jQuery to search the contents
.filter-category li ul {
  display: none;
}

.filter-category ul {
  margin-bottom: 0;
}
.filter-category ul li {
  list-style: none;
  padding-bottom: 20px;
  font-size: 16px;
}
.filter-category ul li:last-of-type {
  padding-bottom: 0;
}
.filter-category ul li a {
  color: #000;
  text-decoration: none;
}

.filter-category ul li a:hover {
  text-decoration: underline;
}
.filter-category ul li ul {
  padding-left: 25px;
  padding-top: 16px;
  font-style: italic;
}

.sec-w-sidebar .container {
  max-width: 1200px;
  padding-left: 60px;
}
.sec-w-sidebar .sec-left {
  position: relative;
  background: #e1e1e1;
  padding-top: 50px;
}
.sec-w-sidebar .sec-left:before {
  position: absolute;
  right: 100%;
  width: 1000%;
  height: 100%;
  content: "";
  top: 0;
  background: #e1e1e1;
}
.sec-w-sidebar .sec-left h3 {
  font-size: 25px;
  color: $primary;
  margin-bottom: 20px;
}
.sec-w-sidebar .sec-left ul li {
  padding-bottom: 15px;
  list-style: none;
  font-size: 14px;
}
.sec-w-sidebar .sec-left ul li ul {
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 15px;
}

.sec-w-sidebar .sec-left ul li a {
  color: #000;
  text-decoration: none;
}

.sec-w-sidebar .sec-left ul li a:hover {
  text-decoration: underline;
}
.sec-right {
  padding-top: 53px;
}
.sec-right h1,
.program-desc h2 {
  color: $primary;
  padding-bottom: 10px;
}
.sec-w-sidebar .sec-left ul li ul li:last-of-type {
  padding-bottom: 0;
}

.sec-right-w-spc {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.cate-tag,
.cate-tags li {
  font-size: 12px;
  line-height: 18px;
  font-weight: 900;
  padding-bottom: 10px;
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.cate-tags {
  font-size: 0;
}
.cate-tags li {
  position: relative;
  margin-right: 20px;
  padding-bottom: 0;
  letter-spacing: 0.5px;
}
.cate-tags li:last-of-type:after {
  border: none;
}

.cate-tags li::after {
  position: absolute;
  left: 100%;
  margin-left: 10px;
  width: 1px;
  height: 100%;
  border-right: 1px solid #000;
  content: "";
}

.stat-sec {
  padding: 60px 20px;
  background: $primary;
}

.stat-sec .col {
  border-right: 1px solid $primary-light-accent;
  padding-left: 45px;
  padding-right: 45px;
}
.stat-sec .col:last-of-type {
  border-right: none;
}
.stat-sec .col h2 {
  font-size: 120px;
  color: #fff;
  padding-bottom: 0px;
  line-height: 96px;
}
.stat-sec .col h2.stat-sec-heading-medium {
  font-size: 55px;
  line-height: 47px;
}
.stat-sec .col h2 sup {
  font-size: 60%;
}
.stat-sec .col p {
  font-family: "serif";
  font-weight: normal;
  color: #fff;
}

.list-links a {
  font-size: 19px;
  margin-bottom: 22px;
}
.list-links a[class^="col-"],
.list-links div[class^="col-"] {
  padding: 0;
}

.list-links.w-bdr-btm a {
  border-bottom: 1px solid #c2c2c2;
  color: $primary;
  padding-bottom: 20px;
  margin: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33% - 20px);
  flex: 0 0 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
}
.list-links.w-bdr-btm a span {
  font-size: 13px;
  font-weight: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: 1px;
}
.list-links.w-bdr-btm a span img {
  margin-left: 10px;
}
.list-links.w-bdr-btm a:first-of-type,
.list-links.w-bdr-btm a:nth-of-type(2),
.list-links.w-bdr-btm a:nth-of-type(3) {
  padding-top: 0 !important;
}

.list-links-two {
  border-bottom: 1px solid #c2c2c2;
  padding: 0;
}
.list-links .list-links-two a {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: none;
  padding-bottom: 0;
  max-width: 50%;
}
.list-links .list-links-two a:first-of-type {
  padding-right: 10px;
}

.facility-sec .media {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #c2c2c2;
}
.facility-sec .media:first-of-type {
  padding-top: 0;
}
.facility-sec .media img {
  margin-right: 30px;
}
.facility-sec .media a {
  color: $primary;
  display: inline-block;
}
.facility-sec .media a:hover {
  text-decoration: underline;
}
.facility-sec .media p {
  font-size: 16px;
  line-height: 22px;
}

.video-slider .carousel-indicators {
  top: 100%;
  margin-top: 15px;
  position: static;
}
.faculty-sec-mobile {
  overflow: hidden;
}
.carousel-indicators li,
.video-slider .carousel-indicators li,
.faculty-sec-mobile .carousel-indicators li,
.faculty-slider-thumb .slick-dots li button {
  margin-left: 6px;
  margin-right: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #c2c2c2;
  border: none;
}
.faculty-slider-thumb .slick-dots {
  text-align: center;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
}
.faculty-slider-thumb .slick-dots li {
  display: inline-block;
}
.faculty-sec-mobile .slick-dots li button {
  font-size: 0;
}
.video-slider .carousel-indicators li.active,
.carousel-indicators li.active {
  background: $primary;
}
.faculty-slider-thumb .slick-dots li.slick-active button {
  background: #fff;
}
.faculty-sec-mobile .carousel-caption a {
  color: #fff;
}
.video-slider p {
  font-size: 16px;
}
#studentProjects .carousel-control-next,
#studentProjects .carousel-control-prev {
  width: 7%;
  opacity: 1;
}

#studentProjects .carousel-control-next {
  left: 100%;
}
#studentProjects .carousel-control-prev {
  right: 100%;
  left: auto;
}
#studentProjects .carousel-control-next:hover,
#studentProjects .carousel-control-prev:hover {
  opacity: 0.7;
}
.video-img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.video-icon-img {
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 56px;
  color: #fff;
}
.carousel-control-prev-icon {
  background-image: url(../../images/prev-icon.svg);
}
.carousel-control-next-icon {
  background-image: url(../../images/next-icon.svg);
}
#homePageHeroSlider .carousel-control-prev-icon {
  background-image: url(../../images/prev-icon-white.svg);
}
#homePageHeroSlider .carousel-control-next-icon {
  background-image: url(../../images/next-icon-white.svg);
}

.faculty-sec-mobile .carousel-inner {
  overflow: visible;
}

.faculty-sec-mobile .carousel-caption {
  position: relative;
  left: 0;
  right: 0;
  text-align: left;
  padding: 50px 20px 45px;
  top: 0;
}
.content-detail-sec h3 {
  font-size: 1.7rem;
}
.content-detail-sec h1,
.content-detail-sec h2,
.content-detail-sec h3,
.content-detail-sec h4,
.content-detail-sec h5,
.content-detail-sec h6 {
  color: $primary;
  margin: 2rem 0 1rem 0;
}
.content-detail-sec h4 {
  text-transform: none;
}




.content-detail-sec .cate-tags {
  padding-left: 0;
  font-size: 0;
}
.content-detail-sec .cate-tags li a:hover {
  color: inherit;
}

.content-detail-sec p {
  padding-bottom: 25px;
}

.content-detail-sec .media figcaption,
.video-sec p,
.tab-content p {
  font-size: 16px;
  line-height: 22px;
}




.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 3px solid #fff;
  padding-left: 0 !important;
  list-style: none;
  padding-bottom: 0 !important;
}
.tabs li {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;

  background: #e1e1e1;
  text-align: center;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  color: $primary;
  letter-spacing: 1.2px;
  cursor: pointer;
}
.tabs li a {
  color: $primary;
  padding: 23px 20px;
  display: block;
}

.tabs li .active {
  background: $primary;
  color: #fff;
}
.tab-pane {
  background: #e1e1e1;
  padding: 40px;
}
.tab-content p a:hover {
  color: $primary;
  text-decoration: none;
}
.tab-content p a {
  text-decoration: underline;
}
.tab-content h4 {
  text-transform: none;
  font-weight: normal;
}

.faculty-slider-text,
.faculty-slider-text a {
  color: #fff !important;
}

.faculty-slider-text .popup-desc {
  padding-top: 55px;
}

.faculty-slider-text .popup-desc h3 {
  margin-bottom: 28px;
}

.toggleMenuDropdown.programMenu li.dropdown .dropdown-menu {
  display: block;

  background: transparent;

  position: relative !important;

  border: none;

  -webkit-transform: none !important;

  transform: none !important;

  top: auto !important;
}
.toggleMenuDropdown li a {
  display: inline-block;
}

.program-desc .button-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.program-desc .button-group .btn {
  min-width: inherit;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.full-w-img-sec h4 {
  color: $primary;
}

.dropdown-menu.search.collapsing {
  -webkit-transition: none;
  transition: none;
}
.faculty-slider-sec {
  position: relative;
}
.faculty-slider-sec .buttons {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  bottom: 10px;
}

.quick-facts strong {
  font-size: 50px;
  line-height: 50px;
  font-family: "Flama Condensed";
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
  text-transform: uppercase;
}
.quick-facts strong span {
  font-size: 120px;
  line-height: 92px;
}
.quick-facts {
  padding-left: 0;
}
.quick-facts li {
  padding-bottom: 35px;
  font-family: "serif";
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  list-style: none;
}

.hero.hero-inner.w-overlay h1 {
  color: #fff;
}

/* for hours schedule display: */
#scheduleContent .table td p {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 0px;
}
#scheduleContent ul li p {
  font-size: 16px;
  line-height: 1;
}
.pageheader {
  margin-top: 2.5rem;
}

/* Form Builder Module: */
.form-builder .help_text {
  margin-top: 5px;
  text-align: right;
}
.form-builder .control-label {
  font-size: 14px;
}
.form-builder fieldset {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-builder .checklabel {
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 2em;
  font-size: 14px;
}
.form-builder .radio input, .form-builder .checkbox-inline input {
  vertical-align: bottom;
}
.form-builder .form-control {
  height: auto;
}

/* Academic Program Options module: */
.optrow {
  font-size: 19px;
  margin-top: 0.5em;
}
ul.list-links li.optrow {
  list-style: none;
}

/* resource links (detailed view): */
.resource-links-detailed {
  list-style: none;
}
.resource-links-detailed>li {
  border-bottom: 1px solid #c2c2c2;
  margin-top: 1em;
  padding-bottom: 1em;
}
.resource-links-detailed>li>a {
  color: $primary;
  margin: 0;
  font-size: 19px;
  text-decoration: underline;
  font-weight: bold;
}
.resource-links-detailed>li>.link-description {
  font-size: 16px;
  line-height: 22px;
}
.resource-links-detailed>li>.link-description ul {
  padding-left: 2em;
}

