.m-featured-img-content {

    color: $primary;

    &--dark {
        color: white;
    }


    &__heading {
        @include module-heading($module-heading-size);
        color: $primary !important; // required because vendor CSS has a !important rule .featured-section .bg-primary *
    }

    &--dark &__heading {
        color: white !important; // required because vendor CSS has a !important rule .featured-section .bg-primary *
    }

     & .a-sub-head {
        color: $gray-900;
    }

    &--dark & .a-sub-head {
        color: white;
    }

    // lots of selectors should be cleaned up when vendor styles fo homepage are less specific
    &__content,
    &__content a,
    &__content p {
        color: black;
    }

    &__link {
        margin-top: 1.5rem;
        text-transform: uppercase;
    }
}