.program-finder {
  #filteredGrid {
    a:focus,
    *:focus {
      outline-color: $primary-dark;
    }
  }

  .checkbox-style:focus-within span::after {
    outline: 3px solid $primary-dark;
    outline-offset: 1px;
  }

  #filter-search:focus {
    outline: 3px solid $primary-dark;
    background-color: #fff;
    color: #000;
  }
}
