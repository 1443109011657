.m-full-width-img-feature {

    color: $primary;


    &__overlay {
        &--dark::after {
            background-color: hsla(0, 0%, 0%, 0.5);
        }
    }
} 