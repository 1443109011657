
.skipnav {
    text-align: left;
    &:focus-within {
        padding: 1rem;
        background: #5B182E;
        background: -moz-linear-gradient(top, #5B182E 0%, #5B182E 76%, #48142A 100%);
        background: -webkit-linear-gradient(top, #5B182E 0%, #5B182E 76%, #48142A 100%);
        background: linear-gradient(to bottom, #5B182E 0%, #5B182E 76%, #48142A 100%);
    }
    a {
        position: absolute;
        left: -10000px;
        width: 1px;
        height: 1px;
        overflow: hidden;
        color: #000;
    }
    a:focus,
    a:active {
        position: relative;
        left: 5px;
        width: auto;
        height: auto;
        overflow: visible;
        text-decoration: underline;
        padding: 1.2rem;
        margin: 1rem;
        font-size: 1.2rem;
        background-color: $primary;
        color: $white;
        outline-color: #48142A;
    }
}
