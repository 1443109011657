.m-my-link {
  @extend .col-auto;

  &__icon {
    @extend .img-fluid;
  }

  &__title {
    @extend .p-1;
    float: left;
    width: 90px;
    color: $white;
  }

  border-bottom: solid $primary;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.m-my-link:hover {
  border-bottom: solid white;
  background: $primary-dark;
}
.m-my-link:active {
  border-bottom: solid white;
  background: $primary-dark;
}
.m-my-link:focus {
  border-bottom: solid white;
  background: $primary-dark;
}

.m-my-umt-nav {
  @extend .row;
  @extend .container;
  @extend .p-0;
  @extend .pt-30;
  //@extend .bg-umt-dark-gray;

  &__heading-col{
    @extend .col-md;
  }

  &__heading{
    @extend .h2;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  &__buttons-col {
    @extend .col-auto;
    @extend .ml-auto;
    @extend .mr-auto;
    padding-right: 10px;
  }

  &__button {
    @extend .btn-lg;
    @extend .btn-griz;
    @extend .m-1;
    font-weight: 600;
    -webkit-transition: background 0.3s ease, border 0.3s ease;
    transition: background 0.3s ease, border 0.3s ease;
  }

  &__button--current {
    border-left: 0;
    border-right: 0;
    border-bottom: solid;
    padding-bottom: 5px;
  }

  &__button--current:hover {
    background: $primary !important;
  }

  &__button:hover {
    background: $primary-dark;
    text-decoration: none;
    border-left: 0;
    border-right: 0;
    border-bottom: solid;
    padding-bottom: 5px;
  }
}