

img {
    &.pull-left{
        margin-right: $spacer*2;
        float: left;
        margin-bottom: $spacer*2;
    }
    &.pull-right{
        margin-left: $spacer*2;
        float: right;
        margin-bottom: $spacer*2;
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
        height: auto;

        &.pull-right, &.pull-left {
            float: none !important;
            text-align: center;
            margin: $spacer 0;
        }
    }

}



//figures and captions that occur in the WYSIWYG field
figure {
    margin-bottom: $spacer*2;

    &.pull-left{
        margin-right: $spacer*2;
        float: left;
    }
    &.pull-right{
        margin-left: $spacer*2;
        float: right;
    }

    figcaption {
        font-size: .9rem;
        line-height: 1rem;
        text-align: center;
        margin: $spacer 0 0 0;
        font-style: italic;
        color: #4a4a4a;
        width: 100%;
        display: block;
    }

    @include media-breakpoint-down(xs) {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }

        &.pull-right, &.pull-left {
            float: none !important;
            text-align: center;
            margin: $spacer 0;

            &>figcaption {
                display: inline-block;
            }
        }
    
    }
}
