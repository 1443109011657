.m-event h4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.m-event__datetime {
    @extend .pb-1;
    font-size: 1rem;
}

.m-event {
    @extend .row;
    border-top: 2px solid $umt-maroon;
    -webkit-appearance : none;
    -moz-apperance: none;
    appearance: none;
    margin-right: 0;
}

.m-event__content {
    @extend .col-md;
    @extend .pt-2;
}

.m-event__image {
    @extend .p-0;
    @extend .col-md-3;
}

.m-event__success-btn {
    @extend .btn;
    @extend .btn-success;
    @extend .btn-sm;

}

.m-event__primary-btn {
    @extend .btn;
    @extend .btn-griz;
    @extend .btn-sm;
}


