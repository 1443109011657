.m-featured-img-links {

  color: $primary;


  &__overlay {
    &--dark::after {
      background-color: hsla(0, 0%, 0%, 0.5);
    }
  }
}

.m-featured-image-links {
  @extend .container;
  @extend .d-none;
  @extend .d-md-block;

  color: $primary;

  &__overlay {
    @extend .w-overlay;
    @extend .program-list;

    margin: 0 0 1rem;
    display: block;

    &--dark::after {
      background-color: hsla(0, 0%, 0%, 0.5);
    }

    a{
      display: block;
      text-shadow: 1px 1px 2px #000;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 13px;
      z-index: 1;
      width: 100%;
      text-align: center;
      line-height: 17px;
      transition: all .35s ease;
      color: #fff;
      text-transform: uppercase;
      font-weight: 900;
      text-decoration: none;
      letter-spacing: 1.5px;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);

      &:hover {
        text-decoration: underline
      }
    }
  }


}