.o-events {
    &__item {
        padding-bottom: 1.25rem !important;
    }
}
.o-events ul.o-events__list  {
    list-style-type: none;
    line-height: normal;
}


.o-events ul li .o-events__btn {
    color: #fff;
    text-decoration: none;
    font-weight: 900;
    font-size: 0.75rem;
}