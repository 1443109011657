.m-cards {
  &--dark {
    color: white;
    background-color: $primary;
  }

  &__card {
    @extend .card;
    min-width: 14rem;
    margin: 0 4px 4px 0;
    @media only screen and (max-width: 767px) {
      margin: 0 0 4px 0;
    }
    background: transparent;
    @include focus-defaults();
  }

  &--dark &__card {
    border: 0;
    background-color: transparent;
    @include focus-dark();
    & a {
      color: white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: white;
      }
    }
  }

  &__content {
    @extend .card-text;
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-size: 1rem;
    & a {
      color: $primary;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  &__body {
    @extend .card-body;
    margin: 0;
  }

  &__img {
    @extend .card-img-top;
    @extend .mb-3;
  }

  &__title {
    @extend .card-title;
    @include sans-serif();
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    text-transform: none !important;
    color: inherit;
  }

  &__link {
    color: inherit;
    text-decoration: underline;
  }

  &__footer {
    @extend .card-footer;
    font-size: 1rem;
  }

  &--dark &__footer {
    border: 0;
    background-color: transparent;
  }

  &__deck {
    @extend .card-deck;
    @extend .container;
    @extend .row;

    margin: 0 auto;
    padding: 30px 0;
    @media only screen and (max-width: 767px) {
      padding: 15px 0;
    }

    &--feature-card {
      display: block;
    }
  }
}
