footer.site-footer {
  & a:focus {
    outline: 3px solid $white;
    outline-offset: 1px;
  }
  // consistent focus/touch target for column links
  & li a {
    display: block;
  }
}
