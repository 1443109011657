
ul, ol {
    padding-left: 0;
    margin-left: 2rem;
    line-height: 1.2rem;
    padding-bottom: 0;

}

li { 
    @extend p;
}



ol {
    li {
        
        list-style-position: outside;
        font-variant-numeric: ordinal;
        list-style-type: decimal;
    }
    
    li > ol li {
        list-style-type: lower-alpha;
    }
    
    li > ol li > ol li {
        list-style-type: decimal;
    }

    li > ol li > ol li > ol li {
        list-style-type: lower-alpha;
    }

    li > ol li > ol li > ol li > ol li {
        list-style-type: decimal;
    }
    
}



ul {
    li {
        list-style-position: outside;
    }
}



@include media-breakpoint-down(xs) {
    ul, ol {
        padding-left: .25rem;
        margin-left: .5rem;
    }
}

