.bg-primary.content-detail-sec h1, .bg-primary.content-detail-sec h2, .bg-primary.content-detail-sec h3, .bg-primary.content-detail-sec h4, .bg-primary.content-detail-sec .heading {
	 color: #fff;
}
 .bg-primary .cate-tags li::after {
	 border-right: 1px solid #fff;
}
 .bg-primary a, .bg-primary a:hover, .bg-primary a:visited, .bg-primary a:focus, .bg-primary p a:hover, .bg-primary.featured-section p a:hover, .bg-primary.centered-text-sec p a:hover, .bg-primary .featured-section p a:hover, .bg-primary .centered-text-sec p a:hover, .bg-primary ul li a:hover, .bg-primary ol li a:hover {
	 color: #fff;
}
 .bg-primary .bg-white a, .bg-primary .bg-white a:hover, .bg-primary .bg-white a:visited, .bg-primary .bg-white a:focus, .bg-primary .bg-white p a:hover, .bg-primary .bg-white.featured-section p a:hover, .bg-primary .bg-white.centered-text-sec p a:hover, .bg-primary .bg-white .featured-section p a:hover, .bg-primary .bg-white .centered-text-sec p a:hover, .bg-primary .bg-white ul li a:hover, .bg-primary ol li a:hover {
	 color: #000;
}
 .bg-primary .btn-outline-secondary {
	 color: #fff;
	 border-color: #fff;
	 border-radius: 0;
	 border-width: 3px;
	 letter-spacing: 1px;
	 font-weight: 900;
	 background: $primary;
}
 .bg-primary .btn-outline-secondary:hover {
	 background: #fff;
	 color: $primary;
	 border-color: #fff;
}
 .bg-primary .l-stl-none li a {
	 color: #fff;
}
 .bg-primary.content-detail-sec ul li ul li::after {
	 background-color: #fff;
}
 .sec-w-sidebar .sec-left.unit-interior ul li ul {
	 padding-bottom: 0;
}
 .bg-primary .bg-white .filter-category h4 a, .bg-primary .bg-white .filter-category h4 a:visited, .bg-primary .bg-white .filter-category h4 a:hover, .bg-primary .bg-white .filter-category h4 a:focus {
	 color:$primary;
}
 .filter-category.grid {
	 display:block;
	 float:left;
	 width:33%;
	 padding-right: 15px;
	 padding-left: 15px;
	 margin-bottom:25px;
}
 .filter-category.grid:nth-child(3n) {
	 margin-right:0;
}
 .program-finder .checkbox-style span {
	 text-transform: uppercase;
}
 .program-finder .sizer {
	 width:33%;
}
 .program-finder .search .form-control {
	 width:100%;
}
 .program-finder .filter-category.grid li {
	 display:none;
}
 .program-finder .filter-category.grid li.filterable {
	 display:block;
}
 @media only screen and (max-width: 768px) {
	 .filter-category.grid, .program-finder .sizer {
		 width:50%;
	}
}
 @media only screen and (max-width: 767px) {
	 .filter-category.grid, .program-finder .sizer {
		 width:100%;
	}
}
 .page-home .carousel-control-prev-icon {
	 background-image: url(../../images/prev-icon-white.svg);
}
 .page-home .carousel-control-next-icon {
	 background-image: url(../../images/next-icon-white.svg);
}
 .carousel.row-block .carousel-control-next, .carousel-control-prev {
	 width: 7%;
	 opacity: 1;
}
 .carousel.row-block .carousel-control-next {
	 left: 100%;
}
 .carousel.row-block .carousel-control-prev {
	 right: 100%;
	 left: auto;
}
 .carousel.row-block .carousel-control-next:hover, .carousel.row-block .carousel-control-prev:hover {
	 opacity: 0.7;
}
 .mrl-37 {
	 margin-right:75px;
	 margin-left:75px;
}
/*Facilities Block*/
 .facility-sec .media .facility-heading {
	 font-size: 19px;
	 color: $primary;
}

.facility-sec ul li:last-child {
    border-bottom: 0;
}

/*Two Column Image Block */
 .two-img-column-link{
	 margin-top:26px;
	 display: block;
}
/*Factiod Block - allow for different heading levels*/
 .stat-sec-heading {
	 font-size: 120px;
	 color: #fff;
	 padding-bottom: 0px;
	 line-height: 96px;
	 font-family: "Flama Condensed";
	font-weight: 500;
	margin-bottom: 15px;
	text-transform: uppercase;
}
 .stat-sec-heading sup {
	 font-size: 60%;
}
.stat-sec-heading.stat-sec-heading-medium {
	font-size: 55px;
	line-height: 47px;
	min-height: 96px;
}
.stat-sec-heading.stat-sec-heading-spacing {
	word-spacing: 1024px;
}

/*Program Highlights Block- allow for different heading levels*/
 .heading-sec.w-bdr-baseline .heading {
	 position: relative;
	 line-height: 30px;
}
 .heading-sec.w-bdr-baseline .heading::after {
	 content: "";
	 position: absolute;
	 left: 0;
	 bottom: 5px;
	 background: #c2c2c2;
	 width: 100%;
	 height: 1px;
	 z-index: -1;
}
 .pr-90 {
	 padding-right: 90px !important;
}
/*Video block*/
 .video-content {
	 font-size: 16px;
	 line-height: 22px;
}
/*Carousel block*/
 .ml-37{
	 margin-left: 37px !important;
}
 .pr-75 {
	 padding-right: 75px !important;
}
 .nonhero .carousel-control-next{
	 left: 100%;
	 width: 7%;
	 opacity: 1;
}
 .nonhero .carousel-control-prev{
	 right: 100%;
	 left: auto;
}
 @media only screen and (max-width: 767px){
	 .m-nmlr-20 {
		 margin-left: -20px !important;
		 margin-right: -20px !important;
	}
	 .m-pr-0 {
		 padding-right: 0 !important;
	}
	 .nonhero .carousel-control-prev, .nonhero .carousel-control-next {
		 display: none;
	}
}
/*Additional Resources Block*/
 .list-links.w-bdr-btm a{
	 margin-right: 10px;
	 margin-left: 10px;
}
/*Accordion/Tabs Block*/
 .accordion .subheading {
	 color: $primary;
	 text-transform: uppercase;
}
 .accordion-title .subheading span {
	 padding: 26px 0;
	 border-bottom: 1px solid #c2c2c2;
	 display: block;
	 cursor: pointer;
}
 .accordion-item:first-of-type .accordion-title .subheading span {
	 padding-top: 0;
	 border-top: none;
}
 .accordion-title .subheading span i {
	 margin-right: 10px;
	 font-size: 15px;
}
 @media only screen and (max-width: 767px){
	 .accordion-title .subheading {
		 text-transform: none;
	}
}
 @media only screen and (max-width: 1100px) {
	 .featured-section.fourBox .featured-text-sec .heading {
		 font-size: 30px;
		 margin-bottom: 15px;
		 line-height: 30px;
	}
}
 