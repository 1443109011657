//fix FOUC of a stencilJS web component
// https://bbellmyers.github.io/2020/09/27/fouc-stencil.html
umt-global-nav {
  min-height: 120px;
  background-color: $primary;

  &:not(.hydrated) > * {
    visibility: hidden;
  }
}
