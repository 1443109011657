// Resolve links that overflow the container.

//Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/

a {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


// Headings are linked, but inconsistently, It's hard to tell what is and is not a link.
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    text-decoration: underline;
    color: inherit;
    text-underline-offset: 5%;

    &:hover {
        text-decoration: none;
    }
}