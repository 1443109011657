.m-highlights {


    &__heading {
        @include module-heading($module-heading-size);
    }

    &__item {
        margin-bottom: 10;
        text-decoration: none;
       

        a {
            text-decoration: none;
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }
}