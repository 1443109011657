@font-face {
    font-family: 'Flama Condensed';
    src: url('../fonts/FlamaCondensed-Medium.woff2') format('woff2'),
        url('../fonts/FlamaCondensed-Medium.woff') format('woff'),
        url('../fonts/FlamaCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../fonts/FlamaCondensed-Extrabold.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Extrabold.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Extrabold.ttf') format('truetype');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../fonts/FlamaCondensed-Light.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Light.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }

// // Avenir LT Std is our body copy font
// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Oblique.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Oblique.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Oblique.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Flama Condensed Basic';
//     src: url('../fonts/FlamaCondensed-Basic.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Basic.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Basic.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../fonts/FlamaCondensed-Black.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Black.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Medium.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Medium.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Book.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Book.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-LightOblique.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-LightOblique.woff') format('woff'),
//         url('../fonts/AvenirLTStd-LightOblique.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Flama Condensed Book';
//     src: url('../fonts/FlamaCondensed-Book.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Book.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Book.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../fonts/FlamaCondensed-Bold.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Bold.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Roman.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Roman.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Black.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Light.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Light.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-HeavyOblique.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-HeavyOblique.woff') format('woff'),
//         url('../fonts/AvenirLTStd-HeavyOblique.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-BookOblique.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-BookOblique.woff') format('woff'),
//         url('../fonts/AvenirLTStd-BookOblique.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-MediumOblique.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-MediumOblique.woff') format('woff'),
//         url('../fonts/AvenirLTStd-MediumOblique.ttf') format('truetype');
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../fonts/FlamaCondensed-Semibold.woff2') format('woff2'),
//         url('../fonts/FlamaCondensed-Semibold.woff') format('woff'),
//         url('../fonts/FlamaCondensed-Semibold.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-BlackOblique.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-BlackOblique.woff') format('woff'),
//         url('../fonts/AvenirLTStd-BlackOblique.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Avenir LT Std';
//     src: url('../fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
//         url('../fonts/AvenirLTStd-Heavy.woff') format('woff'),
//         url('../fonts/AvenirLTStd-Heavy.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'ArnoPro';
//     src: url('../fonts/ArnoPro-Italic.woff2') format('woff2'),
//         url('../fonts/ArnoPro-Italic.woff') format('woff'),
//         url('../fonts/ArnoPro-Italic.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'ArnoPro';
//     src: url('../fonts/ArnoPro-Regular.woff2') format('woff2'),
//         url('../fonts/ArnoPro-Regular.woff') format('woff'),
//         url('../fonts/ArnoPro-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'ArnoPro';
//     src: url('../fonts/ArnoPro-Bold.woff2') format('woff2'),
//         url('../fonts/ArnoPro-Bold.woff') format('woff'),
//         url('../fonts/ArnoPro-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }



