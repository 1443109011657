blockquote {
    background: $gray-100;
    border-left: .5rem solid $gray-200;
    margin: 2rem 0rem 4rem 0rem;
    padding: 1.5rem 1.5rem;
    font-style: italic;

    p:last-child {
        padding-bottom: 0;
    }

    a{
        text-decoration: underline;
    }

    &:before {
        color: $gray-600;
        font-size: 3rem;
        line-height: 0.1rem;
        margin-right: 0.25rem;
        vertical-align: -1.2rem;
    }
}

blockquote, blockquote p {
    font-size: 1.5rem;
    line-height: 2.25rem;
}

@include media-breakpoint-down(xs) {
    blockquote, blockquote p {
        font-size: 1rem;
        line-height: 1.25rem;
    }
}