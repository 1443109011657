.m-featured-img {

    &__caption {
        color: $gray-700;
    }

    &__overlay {
        &--dark::after {
            background-color: hsla(0, 0%, 0%, 0.5);
        }
    }
} 