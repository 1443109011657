.o-homepage-events {
    &__btn {
        color: $white !important;
    }

    &__list {
        @extend .row;
        @extend .flex-wrap;
    }

    &__event-btn {
        background: #70002e;
        border-style: none;
        color: #fff;
        font-size: 19px;
        font-weight: 400;
        letter-spacing: normal;
        text-align: left;
        padding: 0;
        margin: 0;
    }
    &__event-btn:hover {
        text-decoration: underline;
    }
    &__content {
        height: 378px;
    }
    &__content, &__content div, &__content p {
        color: #343a40
    }
}