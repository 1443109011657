* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 1;
  font-weight: normal;
  font-family: Arial, Helvetica, san-serif;
  margin: 0px;
  padding: 0px;
  color: #000;
  background: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

iframe {
  border: 0px;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
textarea {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}

a[href^="tel"] {
  text-decoration: inherit;
  color: inherit;
}

a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: #000;
}
a:focus {
  // current styles are awful, removing our style in favor of browser default for the moment. This might not make sense to have in the reset file, but instead add focus to each module
}
a.text-primary:focus,
a.text-primary:hover {
  color: #000 !important;
}
a,
img {
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}
.img-obj-fit {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:-moz-placeholder {
  color: transparent;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

.pos-rel {
  position: relative;
  z-index: 1;
}

.container {
  margin: 0 auto;
  max-width: 1110px;
}

.bgImg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

p a,
.featured-section p a,
.centered-text-sec p a,
ul li a,
ol li a {
  color: inherit;
  letter-spacing: normal;
  text-decoration: underline;
  font-weight: normal;
}
p a:hover,
.featured-section p a:hover,
.centered-text-sec p a:hover,
ul li a:hover,
ol li a:hover {
  text-decoration: none;
  color: $primary;
}
.centered-text-sec.text-white p a:hover,
.copyright a:hover {
  color: #000;
}
.lts-2 {
  letter-spacing: 2px !important;
}

.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-19 {
  font-size: 19px !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.l-stl-none {
  list-style: none;
}
.l-stl-none li {
  color: #000;
  padding-bottom: 15px;
}
.l-stl-none li:last-of-type {
  padding-bottom: 0;
}
.l-stl-none li a {
  color: #000;
  text-decoration: none;
}
.l-stl-none li a:hover {
  text-decoration: underline;
  color: inherit;
}

/* FONT WEIGHT */
.fw-900 {
  font-weight: 900 !important;
}

/* PADDING */

.plr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.plr-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.plr-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.ptb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.ptb-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.ptb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.ptb-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.pl-75 {
  padding-left: 75px !important;
}

.pl-90 {
  padding-left: 90px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pr-90 {
  padding-right: 90px !important;
}

.pt-30 {
  padding-top: 30px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-60 {
  padding-top: 60px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}

/* MARGIN */
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mr-37 {
  margin-right: 37px !important;
}
@media only screen and (max-width: 767px) {
  .m-mb-0 {
    margin-bottom: 0 !important;
  }
  .m-pl-0 {
    padding-left: 0 !important;
  }
  .m-pl-20 {
    padding-left: 20px !important;
  }
  .m-m-20 {
    margin: 20px !important;
  }
  .m-mlr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .m-mtb-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-mlr-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .m-nmlr-20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .m-ptb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .m-ptb-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-ptb-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-p-20 {
    padding: 20px !important;
  }
  .m-pb-30 {
    padding-bottom: 30px !important;
  }
  .m-pb-40 {
    padding-bottom: 40px !important;
  }
  .m-mb-20 {
    margin-bottom: 20px !important;
  }
  .m-mb-50 {
    margin-bottom: 50px !important;
  }
  .m-mb-60 {
    margin-bottom: 60px !important;
  }
  .m-mt-20 {
    margin-top: 20px !important;
  }
  .m-mt-30 {
    margin-top: 30px !important;
  }
  .m-mb-15 {
    margin-bottom: 15px !important;
  }
  .m-mb-20 {
    margin-bottom: 20px !important;
  }
  .m-mb-40 {
    margin-bottom: 40px !important;
  }
  .m-plr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-ptb-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-pb-0 {
    padding-bottom: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .m-mb-0 {
    margin-bottom: 0 !important;
  }
  .m-p-0 {
    padding: 0 !important;
  }
  .m-plr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-ptb-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

h1,
h2,
.h1,
.h2,
.toggle-heading
 {
  font-family: "Flama Condensed", Arial, Helvetica, san-serif;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;
}

h2,
.content-detail-sec h1,
.mainHeading,
.h2 {
  font-size: 50px;
  line-height: 50px;
}

h3,
.content-detail-sec h2,
.h3 {
  font-size: 40px;
  line-height: 40px;
}
h4,
.h4 {
  font-size: 19px;
}

p {
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  margin-bottom: 0;
}
b,
strong {
  font-weight: 900;
}
.bg-primary {
  background-color: $primary !important;
}
.bg-secondary {
  background-color: $primary-dark !important;
}
.text-primary {
  color: $primary !important;
}
.text-gray {
  color: $text-muted !important;
}
.btn {
  font-weight: 900;
  letter-spacing: 1px;
}
.button-group {
  text-align: center;
  font-size: 0;
}
.button-group .btn {
  margin: 0 20px 20px 0px;
  font-size: 13px;
  padding: 17px 10px;
  text-transform: uppercase;
  min-width: 150px;
}
.btn-outline-primary {
  color: #fff;
  border-color: #fff;
  border-radius: 0;
  border-width: 3px;
}
.btn-outline-primary:not(:disabled):not(.disabled):focus,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:hover {
  background: #fff;
  color: $primary !important;
  border-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.featured-bgImg .btn-group > .btn-group:not(:last-child) > .btn,
.featured-bgImg .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.featured-bgImg .btn-group > .btn-group:not(:first-child),
.featured-bgImg .btn-group > .btn:not(:first-child) {
  margin-left: 20px;
}

.btn-outline-secondary {
  color: $primary;
  border-color: $primary;
  border-radius: 0;
  border-width: 3px;
  letter-spacing: 1px;
  font-weight: 900;
}
.btn-outline-secondary:hover {
  background: $primary;
  color: #fff;
  border-color: $primary;
}

.btn-white{
  background: #fff;
  color: $primary !important;
  border: 1px solid transparent;
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus{
  border-color: $primary;
  background: $primary;
  color: #fff !important;
}

.btn-primary,
.search .btn:hover,
.search .btn:active,
.search .btn:focus:not(:disabled):not(.disabled):active {
  background: $primary;
  color: #fff;
  border: 1px solid transparent;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus:not(:disabled):not(.disabled):active {
  border-color: $primary;
  background: #fff;
  color: $primary;
}
.filter-sec {
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  padding: 30px 0;
  font-size: 12px;
}

.filter-heading {
  letter-spacing: 1px;
  color: #707070;
  font-size: 12px;
  text-transform: uppercase;
}

.filter-sec .col {
  line-height: 18px;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}
.filter-sec .filter-left .col:first-of-type {
  padding-right: 18px;
  padding-left: 16px;
}
.checkbox-style {
  padding: 0 19px 0 25px;
  margin-bottom: 0;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.checkbox-style input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.checkbox-style span {
  font-weight: 900;
  position: relative;
}
.checkbox-style input span {
  position: relative;
  font-weight: 900;
}
.checkbox-style span:after {
  position: absolute;
  left: -25px;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid $primary;
  content: "";
}
.checkbox-style input:checked + span:after {
  background: $primary;
}

.filter-search {
  padding: 30px;
  background: #e1e1e1;
}
.filter-right {
  -webkit-box-flex: 9999;
  -ms-flex: 9999;
  flex: 9999;
}
