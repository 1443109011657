//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: (
    100: $gray-100,
    200: $gray-200,
    300: $gray-300,
    400: $gray-400,
    500: $gray-500,
    600: $gray-600,
    700: $gray-700,
    800: $gray-800,
    900: $gray-900
);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #17802f; // updated green so there is contrast with white text for WCAG AA compliance
$teal: #20c997;
$cyan: #17a2b8;

$umt-maroon: #70002e;
$umt-dark-gray: #8c8e90;
$umt-light-gray: #d5cdc5;
$umt-web-cream: #fbfbf5;

$umt-green: #aba30a;
$umt-orange: #d7822d;
$umt-teal: #006666;
$umt-brown: #311c0f;

// UM Brand Colors
$primary: $umt-maroon;
$primary-light-accent: #8d485e;
$primary-dark: #5b182e;

// The following are colors that can be extracted for themes/variations

// Generic Colors
// $primary: $gray-700;
// $primary-light-accent: $umt-light-gray;
// $primary-dark: $gray-900;

// SARC Colors
// $primary: #5c4584;
// $primary-light-accent: #7758ac;
// $primary-dark: #443461;

//override for color contrast WCAG requirements
$text-muted: $gray-800;

$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    umt-maroon: $umt-maroon,
    umt-dark-gray: $umt-dark-gray,
    umt-web-cream: $umt-web-cream,
    umt-green: $umt-green,
    umt-orange: $umt-orange,
    umt-teal: $umt-teal,
    umt-brown: $umt-brown
);

$theme-colors: (
    griz: $umt-maroon,
    griz-light: $umt-light-gray,
    umt-maroon: $umt-maroon,
    umt-dark-gray: $umt-dark-gray,
    umt-web-cream: $umt-web-cream,
    umt-green: $umt-green,
    umt-orange: $umt-orange,
    umt-teal: $umt-teal,
    umt-brown: $umt-brown,
    primary: $umt-maroon,
    secondary: $gray-700, //cannot use UM Dark Grey because it is not an accessible contrast ratio with white text.
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800
);
