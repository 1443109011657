@import "variables";
@import "mixins";

/*
    Bootstrap configuration
*/

// a little help... https://stevenwestmoreland.com/2018/01/how-to-include-bootstrap-in-your-project-with-webpack.html

// pre-default allows us to load settings into bootstrap overriding their !default settings
// must be loaded before bootstrap
// see https://getbootstrap.com/docs/4.4/getting-started/theming/#variable-defaults
@import "bootstrap/pre-default";

//import bootstrap from the node module via ~
//@import "~bootstrap/scss/bootstrap";
// app.css was 274k with all of bootstrap

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// folder to overwrite and extend bootstrap components, must be loaded after bootstrap
@import "bootstrap/ext/alert";

/*
Vendor folder contains Hannon Hill code, our goal is to move code out of this location and into components when possible
*/
//TODO vendor should actually be immutable vendored objects, HH should be renamed???
@import "font";
@import "vendor/reset";
@import "vendor/style";
@import "vendor/style-responsive";
@import "vendor/hh-style";
@import "vendor/slate-form";
@import "vendor/olark";

/*
    Components written by UM
*/
@import "components";

/*
    Styles inside the content-detail-sec class (generally provided with WYSIWYG editor
*/
@import "content-detail-sec";

/*
    Styles specific to types of pages
*/
@import "pages";

@include focus-defaults();

.bg-white {
  @include focus-defaults();
}
.bg-primary {
  @include focus-dark();
}

header.navbar a:focus,
header.navbar *:focus,
#secondaryNavigation a:focus {
  outline: 3px solid $white;
  outline-offset: 0px;
}

/* 
    Since we have a sticky header in the /vendor directory, make scroll to (and tab to) snap to a place where the header doesn't cover it
*/

html {
  scroll-padding-top: 130px;
  scroll-padding-bottom: 300px;
}
