@media only screen and (max-width: 1170px) {
  .navbar.navbar-expand-lg > .container {
    padding-left: 15px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 10px;
    padding-right: 10px;
  }
  .info-menu li a {
    padding: 19px 23px;
  }
  .navbar-search {
    padding: 37.5px 25px;
  }
  .info-menu {
    min-width: 85px;
  }
  .sec-right-w-spc,
  .sec-w-sidebar .container {
    padding-left: 60px !important;
  }
  .featured-section.fourBox .container {
    padding-left: 0 !important;
  }
  .featured-section.fourBox .featured-text-sec h3 {
    font-size: 35px;
    margin-bottom: 15px;
    line-height: 35px;
  }
  .featured-section.fourBox .featured-text-sec p + a {
    margin-top: 15px;
  }

  .filter-right {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 15px;
  }
  .filter-sec .col .checkbox-style {
    padding: 0 18px;
  }
  .filter-sec .filter-left .col:first-of-type {
    padding-left: 15px;
    margin-bottom: 0;
  }
  .filter-left {
    width: 100%;
  }
  .stat-sec .col {
    padding-left: 30px;
    padding-right: 30px;
  }
  .filter-sec .col {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1100px) {
  .featured-section.fourBox .featured-text-sec h3 {
    font-size: 30px;
    margin-bottom: 15px;
    line-height: 30px;
  }
  .featured-section.fourBox .featured-text-sec {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .featured-text-sec h3 br {
    display: none;
  }
  .site-footer .col:last-of-type li {
    font-size: 33px;
    margin: 0 5px;
  }
}
@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link i {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .nav-item:hover > .dropdown-menu,
  .nav-item:hover > a::after {
    display: none;
  }
  .dropdown-menu.show {
    display: block !important;
  }
  /*.navbar.sticky-top {
    position: relative;
} */
  .navbar.navbar-expand-lg > .container {
    padding-left: 20px;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background: #72253d;
    border-top: 1px solid $primary-light-accent;
    z-index: 99999;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 19px;
    padding-bottom: 19px;
    border-bottom: 1px solid $primary-light-accent;
    font-size: 16px;
    position: relative;
  }
  .navbar-expand-lg .navbar-nav .nav-link::before {
    position: absolute;
    font-family: fontAwesome;
    font-size: 14px;
    left: 20px;
    font-weight: normal;
  }
  .navbar-expand-lg .navbar-nav .nav-link[aria-expanded="false"]::before {
    content: "\f067";
  }

  .navbar-expand-lg .navbar-nav .nav-link[aria-expanded="true"]::before {
    content: "\f068";
  }
  .navbar-expand-lg .navbar-nav .no-dropdown .nav-link::before {
    display: none;
  }
  .navbar-toggler-icon {
    width: 24px;

    height: 3px;

    display: block;

    margin-bottom: 5px;
  }
  .navbar-toggler[aria-expanded="true"],
  .navbar-search[aria-expanded="true"] {
    background: $primary-dark;
  }
  .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon {
    background: #fff;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background: #fff;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:first-of-type {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: 8px;
    left: 0;
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:last-of-type {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -8px;
    position: relative;
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:nth-of-type(2) {
    opacity: 0;
    visibility: hidden;
  }
  .navbar-toggler {
    padding: 28px 20px;
    border-left: 1px solid $primary-light-accent;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-right: 1px solid $primary-light-accent;
  }
  .navbar-toggler:focus {
    outline: 1px dotted white;
  }
  .dropdown-menu .container {
    max-width: 100%;
    padding: 0 45px;
  }
  .dropdown-menu .container .col-sm-5,
  .dropdown-menu ul li a br {
    display: none;
  }
  .dropdown-col {
    border-right: none;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-menu {
    border-top: none;
    border-bottom: 1px solid $primary-light-accent;
    padding-top: 8px;
    padding-bottom: 30px;
  }
  .dropdown-menu ul li {
    font-size: 14px;
    padding-bottom: 23px;
  }
  .dropdown-menu .dropdown-col:last-of-type ul li:last-of-type {
    padding-bottom: 0;
  }
  .nav-item.show .nav-link {
    border-bottom: none;
  }
  .info-menu.d-none {
    display: none !important;
  }
  .navbar-brand {
    margin-right: 0px;
    margin-bottom: 22px;
  }
  .navbar-toggler,
  .navbar-search {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .navbar-brand img {
    max-width: 150px;
    width: 150px;
  }
  .dropdown-menu.search.show {
    padding-top: 28px;
    border-bottom: none;
  }
  .button-group .btn {
    margin: 0 20px 20px 0px;
  }
  .sec-right-w-spc {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sec-w-sidebar .container {
    padding-left: 30px !important;
  }
  .t-pr-0 {
    padding-right: 0 !important;
  }
  .t-pr-20 {
    padding-right: 20px !important;
  }
  .t-pb-40 {
    padding-bottom: 40px !important;
  }
  .filter-sec .col {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .stat-sec .col {
    border-right: none;
    border-bottom: 1px solid $primary-light-accent;
    padding: 20px 0;
    text-align: center;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .nav-item:hover > a:after,
  .navbar-expand-lg .navbar-nav .nav-item.active a:after {
    width: 0;
  }
  .popup-detail.current {
    height: auto;
  }
  .stat-info .container .row {
    padding: 50px 25px 65px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .stat-info h2 {
    font-size: 100px;
    line-height: 100px;
  }
}
@media only screen and (min-width: 768px) {
  /* .two-col-sec h2 br:first-of-type {
    display: none;
  } */
}
@media only screen and (max-width: 767px) {
  .m-plr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .container,
  .relatedTopicMobile,
  .content-detail-sec .media figcaption {
    padding: 0 20px;
  }
  .hero-inner h1,
  .hero-inner strong {
    font-size: 45px;
    line-height: 45px;
  }
  .h2,
  h2,
  .content-detail-sec h1,
  .mainHeading {
    font-size: 38px;
    line-height: 38px;
  }
  h3,
  .content-detail-sec h2,
  .heading-sec.w-bdr-baseline span {
    font-size: 30px;
    line-height: 30px;
  }
  .search .form-control {
    width: 100%;
    padding-right: 0;
  }
  .search .btn {
    min-width: inherit;
    position: absolute;
    right: 0px;
    background: transparent;
    font-size: 0;
    padding: 0;
    border: none;
  }
  .search .btn:after {
    content: "\f002";
    font-family: "fontAwesome";
    font-size: 26px;
    color: #72253d;
  }
  .dropdown-menu.search.show {
    padding-top: 14px;
    border-bottom: none;
    padding-bottom: 30px;
  }
  .dropdown-menu.search.show .row {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu.search .container {
    padding: 0 20px;
  }
  .two-col-sec h2 br:first-of-type {
    display: block;
  }
  .event-list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 0px);
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .event-list-item:nth-of-type(2) {
    padding-top: 30px;
  }
  .event-list-item:first-of-type {
    padding-top: 0;
  }
  .heading-sec {
    padding-bottom: 20px;
  }
  .heading-sec span {
    display: block;
    padding-top: 7px;
  }
  .event-list,
  .event-list-item {
    margin: 0;
  }
  .news-item {
    margin-bottom: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid $primary-light-accent;
    padding-left: 0;
    padding-right: 0;
  }
  .button-group .btn {
    margin: 0;
    margin-bottom: 20px;
    min-width: 100%;
  }
  .button-group .btn:last-of-type {
    margin-bottom: 0;
  }
  .m-bgImg-none {
    background-image: none !important;
  }

  .m-overlay-none:after {
    display: none;
  }
  .site-footer .container {
    text-align: center;
  }
  .site-footer .col {
    border: none;
    margin-bottom: 30px;
    -ms-flex-preferred-size: auto;
  }
  .site-footer .col:first-of-type {
    margin-bottom: 58px;
  }
  .site-footer .col:nth-of-type(2) {
    border-bottom: 1px solid $primary-light-accent;
  }
  .site-footer.secondary .col:nth-of-type(2) {
    border: none;
  }

  .site-footer .col:nth-of-type(2) ul {
    padding-bottom: 10px;
  }
  .site-footer .col:nth-of-type(3) {
    margin-bottom: 60px;
  }
  .site-footer .col:last-of-type {
    margin-bottom: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    margin-top: 0;
  }
  .site-footer .col:last-of-type li {
    margin: 0 10px;
  }
  .site-footer.secondary .col:last-of-type {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .caption-center figcaption {
    padding: 0 30px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .caption-center figcaption h2 {
    margin-bottom: 0;
    padding-bottom: 0;
    color: #fff;
  }
  .secondary-menu {
    padding: 0;
    position: relative;
  }
  .secondary-menu ul li:last-of-type,
  .secondary-menu ul li {
    padding-bottom: 0px;
    font-size: 16px;
  }
  .secondary-menu ul li {
    padding-left: 0;
    width: 100%;
    max-width: 100%;
    border-left: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .secondary-menu ul li a {
    display: block;
    padding: 14px 0;
  }
  .secondary-menu .col {
    border-left: none;
    padding-left: 0;
    padding-right: 0;
  }
  .secondary-menu .col:first-of-type ul li:first-of-type a,
  .toggleMenuDropdown li:first-of-type a {
    padding-top: 0;
  }
  .secondary-menu-heading {
    font-size: 13px;
    color: #fff;
    font-weight: 900;
    letter-spacing: 2px;
    margin-bottom: 0px;
    display: inline-block;
    padding: 25px 0;
    cursor: pointer;
  }
  .secondary-menu-heading i {
    margin-right: 10px;
    font-size: 26px;
    vertical-align: middle;
  }
  .secondaryMenuDropdown,
  .toggleMenuDropdown {
    display: none !important;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 100%;
    background: #636363;
    width: 100%;
    padding: 0 20px 45px;
  }
  .toggleMenuDropdown {
    z-index: 999;
  }
  .secondaryMenuDropdown.secondaryMenuActive,
  .toggleMenuDropdown.toggleMenuDropdownActive {
    display: block !important;
  }
  .secondary-menu-heading span.fa {
    display: none;
  }
  .secondary-menu-heading span.fa,
  .toggle-heading-sec span.fa {
    font-size: 20px;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
  .secondary-menu-heading-active span.fa,
  .toggle-heading-sec.toggle-heading-sec-active span.fa {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
  }
  .toggle-heading-sec {
    padding: 28px 20px;
    background: #72253d;
    color: #fff;
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 2px;
    cursor: pointer;
    margin-bottom: 0 !important;
  }
  .toggleMenuDropdown {
    display: none;
    width: calc(100% - 40px);
    left: 20px;
    background: #72253d;
    padding: 0 20px 28px;
  }
  .toggle-heading-sec.primary {
    padding: 23px 20px;
  }
  .toggleMenuDropdown.primary,
  .toggle-heading-sec.primary {
    background: #e1e1e1;
  }
  .toggle-heading-sec.primary .toggle-heading {
    font-weight: 500;
  }
  .toggle-heading-sec.primary .toggle-heading,
  .toggle-heading-sec.primary span.fa {
    color: #72253d;
  }
  .toggleMenuDropdown {
    list-style: none;
  }
  .toggleMenuDropdown li {
    padding-bottom: 0;
  }
  .toggleMenuDropdown li a {
    color: #fff;
    padding: 15px 0;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
  }
  .mobile-toggle-sec .container,
  .filter-sec {
    position: relative;
  }
  .news-sec .heading-sec h3 {
    /* padding-bottom: 28px;
    border-bottom: 1px solid #c2c2c2; */
    width: 100%;
  }
  .accordion-title h4 {
    text-transform: none;
  }
  .site-footer.secondary .row {
    padding: 60px 0;
  }
  .sec-w-sidebar .container {
    padding-left: 20px !important;
  }
  .sec-w-sidebar .sec-left::before,
  .program-desc .button-group {
    display: none !important;
  }
  .toggleMenuDropdown.primary {
    width: 100%;
    left: 0;
  }
  .sec-right-w-spc {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sec-w-sidebar .sec-left {
    padding: 0;
  }
  .sec-w-sidebar .sec-left ul li {
    padding-bottom: 0;
  }
  .sec-right {
    padding-right: 0;
    position: relative;
    z-index: 0;
  }
  .relatedTopicMobile .toggleMenuDropdown li {
    padding-bottom: 0;
  }
  .m-plr767-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .relatedTopicMobile .toggleMenuDropdown,
  .w-pos-adj {
    left: 0;
    width: 100%;
  }
  .faculty-slider-text .popup-desc p {
    padding-bottom: 0;
  }
  .m-bg-none {
    background: transparent !important;
  }
  .m-text-primary {
    color: #72253d !important;
  }

  .filter-heading.toggle-heading-sec {
    padding: 0;
    background: transparent;
    color: #707070;
  }
  .filter-right.toggleMenuDropdown {
    background: transparent;
    position: relative;
    padding: 0 15px 30px;
  }
  .filter-sec {
    padding: 20px;
    font-size: 12px;
    border: 1px solid #c2c2c2;
  }
  .filter-sec .col {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 7px;
  }
  .checkbox-style span::after {
    top: 0;
  }
  .filter-heading.toggle-heading-sec span.fa {
    color: #72253d;
  }
  .filter-category h4:first-of-type {
    padding-top: 35px;
  }
  .filter-categories .filter-category:first-of-type h4:first-of-type {
    padding-top: 0;
  }
  .filter-sec .col {
    margin-bottom: 0px;
  }
  .faculty-slider-text .popup-desc p + a {
    margin-top: 25px;
    display: inline-block;
  }
  .faculty-slider-text .popup-desc p + a:hover {
    color: #fff !important;
  }
  .filter-search {
    padding: 20px;
  }
  .toggleMenuDropdown.programMenu li.dropdown .dropdown-menu {
    -webkit-transform: none !important;

    transform: none !important;
    top: 100% !important;
    position: relative !important;
    display: none;
  }
  .toggleMenuDropdown.primary ul {
    background: transparent;
    border-bottom: none;
  }
  .news-sec.sec-right-w-spc {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .facility-sec .media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .facility-sec .media img {
    margin-right: 0;
    margin-bottom: 30px;
  }
  #studentProjects .carousel-control-prev,
  #studentProjects .carousel-control-next {
    display: none;
  }
  .list-links.w-bdr-btm a {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100%);
    flex: 0 0 calc(100%);
    max-width: calc(100%);
  }
  .list-links.w-bdr-btm a:nth-of-type(2),
  .list-links.w-bdr-btm a:nth-of-type(3) {
    padding-top: 30px !important;
  }
  .cate-tag,
  .cate-tags li {
    letter-spacing: normal;
  }

  /*
	Label the data
	*/


  .faculty-slider-text .popup-desc {
    padding-bottom: 0;
  }
  .centered-text-sec.text-primary p {
    padding-bottom: 0;
  }
  .site-footer .col:last-of-type ul {
    display: block;
    clear: both;
    font-size: 0;
  }
  .search .btn:hover::after {
    color: #fff;
  }
  .search .btn:hover {
    background: transparent;
    color: #fff;
  }
  .search .btn-primary:not(:disabled):not(.disabled):active,
  .search .btn:hover,
  .search .btn:active,
  .search .btn:focus,
  .search .btn:focus:not(:disabled):not(.disabled):active {
    background: transparent;
    color: #fff;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@media only screen and (min-width: 641px) {
  .faculty-sec-desktop,
  .is-hidden-till-640 {
    display: block !important;
  }
  .faculty-sec-mobile,
  .is-visible-till-640 {
    display: none !important;
  }
}
@media only screen and (max-width: 640px) {
  .faculty-sec-desktop,
  .is-hidden-till-640 {
    display: none !important;
  }
  .faculty-sec-mobile,
  .is-visible-till-640 {
    display: block !important;
  }
  .featured-bgImg.caption-center figcaption {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }
  .featured-bgImg.caption-center figcaption h2 {
    padding-bottom: 30px;
  }
  .featured-bgImg.w-overlay.caption-center::after {
    z-index: 0;
  }
  .hero.hero-inner.w-overlay.bgImg {
    background-image: none !important;
    padding-top: 0;
    padding-bottom: 0;
  }
  .hero.hero-inner.w-overlay.bgImg .container {
    position: absolute;
    bottom: 45px;
  }
}
@media only screen and (min-width: 576px) {
}
@media only screen and (max-width: 575px) {
  .featured-text-sec h3 br,
  .two-col-sec h2 br {
    display: block;
  }
  .two-col-sec h2 {
    padding: 0 25px;
  }
  .featured-bgImg .container {
    padding: 0 50px;
  }
  .navbar-toggler,
  .navbar-search {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .navbar-brand img {
    max-width: 130px;
  }
  .two-col-sec h2 {
    padding: 0 45px;
  }
}
