.m-program-highlights {
  &--dark {
    color: $white;
    .heading-sec.w-bdr-baseline span{
      background: $primary;
      color: $white;
    }
    ul li a {
      -webkit-transition: none;
      transition: none;
      &:hover {
        color: $white;
      }
    }
  }

}